export const formatNumber = (number, decimalPlaces) => {
  if (number) {
    var numberFormatter = new Intl.NumberFormat(process.env.REACT_APP_LOCALE, {
      style: "decimal",
      maximumFractionDigits: decimalPlaces ? decimalPlaces : 0,
      minimumFractionDigits: decimalPlaces ? decimalPlaces : 0,
    });
    return numberFormatter.format(number);
  } else {
    return number;
  }
};

export const toLocaleDateString = (date) => {
  return new Date(date).toLocaleDateString(process.env.REACT_APP_LOCALE);
};

export const toLocaleShortMonthAndYear = (date) => {
  return new Date(date).toLocaleDateString(process.env.REACT_APP_LOCALE, {
    month: "short",
    year: "numeric",
  });
};
