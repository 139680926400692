import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as BackIcon } from "../../components/icons/arrow_icon.svg";
import DatePicker from "react-datepicker";
import Error from "../common/ServerError";
import { NavLink } from "react-router-dom";
import OverFlowToolTip from "../common/OverFlowToolTip";
import Spinner from "../common/Spinner";
import dateHelper from "../common/dateHelper";
import { isTrue } from "../common/utilities";
import projectAttributeApi from "../../api/projectAttributeApi";
import projectCategoryApi from "../../api/projectCategoryApi";
import { tokenRequest } from "../../authConfig";
import unitOfMeasurements from "../../constants/unitOfMeasurements";
import uomConfigApi from "../../api/uomConfigApi";
import uomData from "../../constants/uoMData";
import { useMsal } from "@azure/msal-react";
import workflowStatusApi from "../../api/workflowStatusApi";

const CreateProject = (props) => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(false);
  const [projectCategories, setProjectCategories] = useState([]);
  const [projectAttributes, setProjectAttributes] = useState(null);
  const [workflowStatuses, setWorkflowStatuses] = useState([]);

  const [dataLoaded, setDataLoaded] = useState({
    categoriesLoaded: false,
    currencyLoaded: false,
    attributesLoaded: false,
    workflowStatusesLoaded: false,
  });

  const [getYearAndMonthRange, datePlaceholder] = dateHelper();
  const [showTimeHorizonValuesError, setTimeHorizonValuesError] =
    useState(false);
  const [timeHorizonValues, setTimeHorizonValues] = useState([]);
  const [project, setProject] = useState(null);

  const todayDate = new Date();
  const fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
  const toDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth() + (props.maxTimeHorizonInMonths - 1),
    1
  );

  const [datesTouched, setDatesTouched] = useState(false);
  const [dates, setDates] = useState({
    validFrom: fromDate,
    validTo: toDate,
    refreshTimeHorizonTable: false,
  });

  const [validFromDate, setValidFromDate] = useState(fromDate);
  const [validToDate, setValidToDate] = useState(toDate);
  const validToMaxDate = toDate;
  const dateFormat = datePlaceholder();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: {
      validFrom: fromDate,
      validTo: toDate,
    },
  });

  const {
    register: timeHorizonRegister,
    formState: { errors: timeHorizonErrors },
    handleSubmit: timeHorizonHandleSubmit,
    reset,
  } = useForm();

  const [currencyData, setCurrencyData] = useState([]);
  const [showCurrency, setShowCurrency] = useState(false);

  const fileInputRef = useRef(null);
  const { ref, ...rest } = register("file");

  const [file, setFile] = useState();
  const saveFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const [fileUploadingStatus, setFileUploadingStatus] = useState({
    isUploading: false,
    response: null,
  });

  useEffect(() => {
    if (fileUploadingStatus && fileUploadingStatus.response) {
      if (fileUploadingStatus.response.status?.toLowerCase() === "success") {
        formReset({
          name: null,
          projectCategoryId: null,
          uoM: null,
          currency: null,
          description: null,
          validFrom: fromDate,
          validTo: toDate,
          file: null,
        });
      } else if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadingStatus]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectCategoryApi
            .list(accessToken)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() =>
              setDataLoaded((prevData) => {
                return {
                  ...prevData,
                  categoriesLoaded: true,
                };
              })
            );

          setProjectCategories(data);
        })();
      })
      .catch((error) => {
        setError(error);
      });
  }, [instance, accounts]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await uomConfigApi
            .list(accessToken)
            .then((data) => {
              setError(false);
              return data;
            })
            .catch((e) => {
              setError(true);
              console.log(e);
            })
            .finally(() =>
              setDataLoaded((prevData) => {
                return {
                  ...prevData,
                  currencyLoaded: true,
                };
              })
            );

          setCurrencyData(data.currency);
        })();
      })
      .catch((error) => {
        setError(true);
      });
  }, [instance, accounts]);

  useEffect(() => {
    if (!isTrue(props.configuration.enableProjectAttributes)) {
      return;
    }

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectAttributeApi
            .list(accessToken)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() =>
              setDataLoaded((prevData) => {
                return {
                  ...prevData,
                  attributesLoaded: true,
                };
              })
            );

          const groupDataByName = Object.groupBy(data, ({ name }) => name);
          setProjectAttributes(groupDataByName);
        })();
      })
      .catch((error) => {
        setError(error);
      });
  }, [instance, accounts, props.configuration.enableProjectAttributes]);

  useEffect(() => {
    if (!isTrue(props.configuration.enableProjectWorkflowStatus)) {
      return;
    }

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await workflowStatusApi
            .get(accessToken, "Project")
            .then((data) => {
              setError(false);
              return data;
            })
            .catch((e) => {
              setError(true);
              console.log(e);
            })
            .finally(() =>
              setDataLoaded((prevData) => {
                return {
                  ...prevData,
                  workflowStatusesLoaded: true,
                };
              })
            );

          setWorkflowStatuses(data);
        })();
      })
      .catch((error) => {
        setError(true);
      });
  }, [instance, accounts, props.configuration.enableProjectWorkflowStatus]);

  const handleUomDropdownSelection = (event) => {
    const result =
      event.target.value === unitOfMeasurements.COST ||
      event.target.value === unitOfMeasurements.PRICE;

    if (!result) {
      formReset({
        ...getValues(),
        currency: null,
      });
    }
    setShowCurrency(result);
  };

  const onProjectBasicDetailsCreate = async (data) => {
    if (isTrue(props.configuration.enableProjectAttributes)) {
      const projectAttributeDetails = Object.entries(projectAttributes)
        .map(([key, values]) => {
          const id = data[key];

          if (!id) {
            return null;
          }

          return {
            attribute: values.find((v) => v.id === parseInt(id)),
            attributeId: id,
            projectId: 0,
          };
        })
        .filter((pa) => pa != null);

      data = {
        ...data,
        projectAttributeDetails: projectAttributeDetails,
      };
    }

    if (props.isFileUpload) {
      const dateRange = getYearAndMonthRange(data.validFrom, data.validTo);

      if (dateRange && dateRange.length > 0 && dateRange.length <= 12) {
        props.handleFileUpload(data, file, setFileUploadingStatus);
        setTimeHorizonValuesError(false);
      } else {
        setTimeHorizonValuesError(true);
      }
    } else {
      setDatesTouched(false);
      setProject(data);

      setDates({
        validFrom: data.validFrom,
        validTo: data.validTo,
        refreshTimeHorizonTable: true,
      });
    }
  };

  const handleValidFromChange = (date) => {
    if (!date) {
      return;
    }

    setValidFromDate(date);

    if (date > validToDate) {
      setValidToDate(toDate);
    }

    handleDatesKeyPress(date);
  };

  const handleValidToChange = (date) => {
    if (!date) {
      return;
    }

    setValidToDate(date);
    handleDatesKeyPress(date);
  };

  const handleDatesKeyPress = (e) => {
    setDatesTouched(true);
    setDates({ ...dates, refreshTimeHorizonTable: false });
  };

  const onTimeHorizonCreate = async (data) => {
    const timeHorizonValuesToBeCreated = timeHorizonValues.map(
      (timeHorizonValue) => {
        return Object.assign(
          {},
          ...props.configuration.attributeConfigurations.map((a) => {
            return {
              ...timeHorizonValue,
              [a.attributeName]:
                data[
                  `${a.displayName}-${timeHorizonValue.formattedTimeBucket}`
                ],
            };
          })
        );
      }
    );

    const request = {
      ...project,
      projectTimeHorizon: timeHorizonValuesToBeCreated,
      validFrom: project.validFrom,
      validTo: project.validTo,
    };

    props.handleAddItemArea(request);
  };

  useEffect(() => {
    if (dates && dates.refreshTimeHorizonTable) {
      reset();
      const dateRange = getYearAndMonthRange(dates.validFrom, dates.validTo);

      if (dateRange && dateRange.length > 0 && dateRange.length <= 12) {
        setTimeHorizonValuesError(false);
        setTimeHorizonValues(dateRange);
      } else {
        setTimeHorizonValuesError(true);
        setTimeHorizonValues([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const getTimeHorizonTemplate = () => {
    const timeHorizonLabels = timeHorizonValues.map((timeHorizon) => {
      return (
        <th scope="col" className="text-end" key={timeHorizon.label}>
          <div style={{ width: "8rem" }}>{timeHorizon.label}</div>
        </th>
      );
    });

    const timeHorizon = props.configuration.attributeConfigurations.map((c) => {
      return (
        <tr key={c.attributeName}>
          {c.displayName ? (
            <th scope="col">
              <div>
                <OverFlowToolTip>{c.displayName}</OverFlowToolTip>
              </div>
            </th>
          ) : null}
          {timeHorizonValues.map((timeHorizon) => {
            return (
              <td
                className="text-end"
                key={`${c.displayName}-${timeHorizon.formattedTimeBucket}`}
              >
                <div>
                  <input
                    className={`text-end form-control ${
                      timeHorizonErrors?.[
                        `${c.displayName}-${timeHorizon.formattedTimeBucket}`
                      ]
                        ? "is-invalid"
                        : ""
                    }`}
                    name={`${c.displayName}-${timeHorizon.formattedTimeBucket}`}
                    type="number"
                    readOnly={!props.hasWritePermissions(c.attributeName)}
                    {...timeHorizonRegister(
                      `${c.displayName}-${timeHorizon.formattedTimeBucket}`,
                      {
                        required: {
                          value: true,
                          message: `The ${timeHorizon.label.toLowerCase()} field is required.`,
                        },
                      }
                    )}
                    {...timeHorizonRegister(
                      `${c.displayName}-${timeHorizon.formattedTimeBucket}`,
                      {
                        required: {
                          value: true,
                          message: `The ${timeHorizon.label.toLowerCase()} field is required.`,
                        },
                      }
                    )}
                    step="any"
                    defaultValue={
                      timeHorizon[c.attributeName.toLowerCase()]
                        ? timeHorizon[c.attributeName.toLowerCase()]
                        : 0
                    }
                  />
                  {timeHorizonErrors?.[
                    `${c.displayName}-${timeHorizon.formattedTimeBucket}`
                  ] && (
                    <span className="invalid-feedback">
                      {
                        timeHorizonErrors?.[
                          `${c.displayName}-${timeHorizon.formattedTimeBucket}`
                        ]?.message
                      }
                    </span>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });

    return (
      <div
        className="col-12 animate fadeIn slow"
        key="create-project-time-horizon-container"
      >
        <form
          onSubmit={timeHorizonHandleSubmit(onTimeHorizonCreate)}
          key="create-project-time-horizon-parameters"
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="table-responsive scrollbar horizontal">
              <div className="scrollbar-content">
                <div className={`col-${timeHorizonValues.length}`}>
                  <table
                    className="time-horizon table table-lg table-borderless table-header-border"
                    key="create-project-time-horizon-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="text-start">
                            {props.configuration.attributeDisplayNameHeader !=
                            null
                              ? props.configuration.attributeDisplayNameHeader
                              : "Name"}
                          </div>
                        </th>
                        {timeHorizonLabels}
                      </tr>
                    </thead>
                    <tbody>{timeHorizon}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="my-3">
            <button
              type="submit"
              className="button me-2"
              disabled={!props.hasWritePermissions()}
            >
              Select Item Areas
            </button>
            <button
              type="button"
              className="button danger"
              onClick={() => props.handleCancel()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  };

  const projectName = watch("name");
  useEffect(() => {
    props.validateProjectName(projectName, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName]);

  if (
    !dataLoaded.categoriesLoaded ||
    !dataLoaded.currencyLoaded ||
    (isTrue(props.configuration.enableProjectAttributes) &&
      !dataLoaded.attributesLoaded) ||
    (isTrue(props.configuration.enableProjectWorkflowStatus) &&
      !dataLoaded.workflowStatusesLoaded)
  ) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const name = watch("name");

  return (
    <React.Fragment>
      {dataLoaded.categoriesLoaded &&
      dataLoaded.currencyLoaded &&
      projectCategories &&
      projectCategories.length > 0 ? (
        <div className="row animate fadeIn slow" key="create-project-container">
          {name ? (
            <div className="sticky-header stuck">
              <div className="basic-details">
                <div className="name">{name} </div>

                <div className="info">
                  {watch("workflowStatus")} {watch("uoM")} {watch("currency")}
                </div>

                {/* <div className="dates">
                {toLocaleDateString(project.validFrom)} -{" "}
                {toLocaleDateString(project.validTo)}
              </div> */}
              </div>
            </div>
          ) : null}
          {!props.hasWritePermissions() ? (
            <div className="mt-2 text-error">
              You don't have time horizon row level permissions
            </div>
          ) : null}
          <div className="flex pt-2">
            <div className="flex">
              <button
                type="button"
                className="link ms-n2"
                onClick={() => props.handleCancel()}
              >
                <span className="arrow-icon back">
                  <BackIcon />
                </span>
                <span className="font-weight-medium">Back</span>
              </button>
            </div>
          </div>
          <div className="col-12 pt-3">
            <h4 className="">Add Project Details</h4>
          </div>

          <div className="col-6" key="create-project-parameters-container">
            <form
              onSubmit={handleSubmit(onProjectBasicDetailsCreate)}
              key="create-project-parameters"
              encType="multipart/form-data"
            >
              <div className="pb-3">
                <h5>Basic Details</h5>
              </div>
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  className={`me-2 form-control ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  name="name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "The name field is required.",
                    },
                  })}
                />
                {errors.name && (
                  <span className="invalid-feedback">
                    {errors.name.message}
                  </span>
                )}
              </div>

              {dataLoaded.workflowStatusesLoaded && workflowStatuses.length ? (
                <React.Fragment>
                  <div className="form-group">
                    <label className="form-label">Workflow Status</label>
                    <select
                      name="workflowStatus"
                      className={`form-control ${
                        errors.workflowStatus ? "is-invalid" : ""
                      }`}
                      change=""
                      {...register("workflowStatus", {
                        required: {
                          value: true,
                          message: "The workflow status field is required.",
                        },
                      })}
                    >
                      {workflowStatuses.map((option) => {
                        return (
                          <option value={option.value} key={option.value}>
                            {option.description}
                          </option>
                        );
                      })}
                    </select>
                    {errors.workflowStatus && (
                      <span className="invalid-feedback">
                        {errors.workflowStatus.message}
                      </span>
                    )}
                  </div>
                </React.Fragment>
              ) : null}

              <div className="form-group">
                <label className="form-label">Category</label>
                <select
                  name="projectCategoryId"
                  className={`form-control ${
                    errors.projectCategoryId ? "is-invalid" : ""
                  }`}
                  change=""
                  {...register("projectCategoryId", {
                    required: {
                      value: true,
                      message: "The category field is required.",
                    },
                  })}
                >
                  <option value="" key="Select Category">
                    Select Category
                  </option>
                  {projectCategories.map((option) => {
                    return (
                      <option value={option.id} key={option.name}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
                {errors.projectCategoryId && (
                  <span className="invalid-feedback">
                    {errors.projectCategoryId.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">UoM</label>
                <select
                  name="uoM"
                  className={`form-control ${errors.uoM ? "is-invalid" : ""}`}
                  {...register("uoM", {
                    onChange: (e) => handleUomDropdownSelection(e),
                    required: {
                      value: true,
                      message: "The uom field is required.",
                    },
                  })}
                >
                  <option value="">Select UoM</option>
                  {uomData.map((option) => {
                    return (
                      <option value={option.value} key={option.value}>
                        {option.description}
                      </option>
                    );
                  })}
                </select>
                {errors.uoM && (
                  <span className="invalid-feedback">{errors.uoM.message}</span>
                )}
              </div>

              {showCurrency ? (
                <div className="form-group">
                  <label className="form-label">Currency</label>
                  <select
                    name="currency"
                    className={`form-control ${
                      errors.currency ? "is-invalid" : ""
                    }`}
                    {...register("currency", {
                      required: {
                        value: true,
                        message: "The currency field is required.",
                      },
                    })}
                  >
                    <option value="">Select Currency</option>
                    {currencyData.map((option) => {
                      return (
                        <option
                          value={option.description}
                          key={option.description}
                        >
                          {option.description}
                        </option>
                      );
                    })}
                  </select>
                  {errors.currency && (
                    <span className="invalid-feedback">
                      {errors.currency.message}
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="form-group pb-3">
                <label className="form-label">Description</label>
                <textarea
                  rows="3"
                  className={`form-control ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  name="description"
                  {...register("description", {
                    required: {
                      value: true,
                      message: "The description field is required.",
                    },
                  })}
                ></textarea>
                {errors.description && (
                  <span className="invalid-feedback">
                    {errors.description.message}
                  </span>
                )}
              </div>

              {dataLoaded.attributesLoaded && projectAttributes ? (
                <React.Fragment>
                  <div>
                    <h5 className="py-3 border-top">Attributes</h5>
                  </div>
                  <div className="row pb-3">
                    {Object.entries(projectAttributes).map(([key, values]) => {
                      return (
                        <div className="col-6" key={`${key}container`}>
                          <div className="form-group">
                            <label className="form-label">
                              {key} {values[0].isMandatory ? "" : "(Optional)"}
                            </label>
                            <select
                              name={key}
                              className={`form-control ${
                                errors?.[key] ? "is-invalid" : ""
                              }`}
                              change=""
                              {...register(`${key}`, {
                                required: {
                                  value: values[0].isMandatory ? true : false,
                                  message: `The ${key.toLowerCase()} field is required.`,
                                },
                              })}
                            >
                              <option value="" key={`${key}selectAttribute`}>
                                Select Attribute
                              </option>
                              {values.map((option) => {
                                return (
                                  <option value={option.id} key={option.id}>
                                    {option.value}
                                  </option>
                                );
                              })}
                            </select>
                            {errors?.[key] && (
                              <span className="invalid-feedback">
                                {errors?.[key].message}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : null}

              <div className="row">
                <div>
                  <h5 className="py-3 border-top">Time Horizon</h5>
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">
                    Valid From ({dateFormat.toLowerCase()})
                  </label>
                  <Controller
                    control={control}
                    name="validFrom"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, name, value },
                      formState: { errors },
                    }) => (
                      <>
                        <DatePicker
                          portalId="root"
                          showPreviousMonths={false}
                          className={`form-control ${
                            errors.validFrom ? "is-invalid" : ""
                          }`}
                          minDate={fromDate}
                          maxDate={validToMaxDate}
                          selected={validFromDate ?? ""}
                          onChange={(date) => {
                            onChange(date);
                            handleValidFromChange(date);
                          }}
                          placeholderText={dateFormat.toLowerCase()}
                          dateFormat={dateFormat}
                          showMonthYearPicker
                        />
                        {errors && errors.validFrom && (
                          <span className="invalid-feedback">
                            The valid from field is required
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">
                    Valid To ({dateFormat.toLowerCase()})
                  </label>
                  <Controller
                    control={control}
                    name="validTo"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, name, value },
                      formState: { errors },
                    }) => (
                      <>
                        <DatePicker
                          portalId="root"
                          showPreviousMonths={false}
                          className={`form-control ${
                            errors.validTo ? "is-invalid" : ""
                          }`}
                          minDate={validFromDate}
                          maxDate={validToMaxDate}
                          selected={validToDate ?? ""}
                          onChange={(date) => {
                            onChange(date);
                            handleValidToChange(date);
                          }}
                          placeholderText={dateFormat.toLowerCase()}
                          dateFormat={dateFormat}
                          showMonthYearPicker
                        />
                        {errors && errors.validTo && (
                          <span className="invalid-feedback">
                            The valid to field is required
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>

              {!props.isFileUpload ? (
                <div className="mb-3 mt-1">
                  <button type="submit" className="button me-2">
                    Time Horizon
                  </button>
                  {dates.refreshTimeHorizonTable &&
                  !showTimeHorizonValuesError ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="button danger"
                      onClick={() => props.handleCancel()}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  <div className="form-group">
                    <label className="form-label">
                      CSV File Value Column Name
                    </label>
                    <select
                      name="timeHorizonColumnName"
                      className={`form-control ${
                        errors.fileUploadValueColumnName ? "is-invalid" : ""
                      }`}
                      {...register("timeHorizonColumnName", {
                        required: {
                          value: true,
                          message:
                            "The CSV file value column name field is required.",
                        },
                      })}
                    >
                      <option value="">Select Name</option>
                      {props.fileUploadColumnValueNames.map((option) => {
                        return (
                          <option
                            value={option.attributeName}
                            key={option.attributeName}
                          >
                            {option.displayName}
                          </option>
                        );
                      })}
                    </select>
                    {errors.timeHorizonColumnName && (
                      <span className="invalid-feedback">
                        {errors.timeHorizonColumnName.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="form-label">CSV File</label>
                    <input
                      {...rest}
                      type="file"
                      className={`form-control ${
                        errors.file ? "is-invalid" : ""
                      }`}
                      name="file"
                      {...register("file", {
                        required: {
                          value: true,
                          message: "The file field is required.",
                        },
                      })}
                      onChange={saveFile}
                      ref={(e) => {
                        ref(e);
                        fileInputRef.current = e;
                      }}
                    />
                    {errors.file && (
                      <span className="invalid-feedback">
                        {errors.file.message}
                      </span>
                    )}
                  </div>
                  <div className="mt-4 form-group">
                    <button
                      type="submit"
                      className="button primary me-2"
                      disabled={
                        fileUploadingStatus.isUploading ||
                        !props.hasWritePermissions()
                      }
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className={`button ${
                        fileUploadingStatus.response?.status?.toLowerCase() ===
                        "success"
                          ? ""
                          : "danger"
                      }`}
                      onClick={() => props.handleCancel()}
                    >
                      {fileUploadingStatus.response?.status?.toLowerCase() ===
                      "success"
                        ? "Back"
                        : "Cancel"}
                    </button>
                  </div>
                  {fileUploadingStatus.response ? (
                    <React.Fragment>
                      <div
                        className={`flex text-${fileUploadingStatus.response.status.toLowerCase()}`}
                      >
                        {fileUploadingStatus.response.message}
                      </div>

                      {fileUploadingStatus.response.records
                        ? props.getFileUploadErrorRecordsTemplate(
                            fileUploadingStatus.response.records
                          )
                        : null}
                    </React.Fragment>
                  ) : fileUploadingStatus.isUploading ? (
                    <span className="ms-2">
                      <span className="me-2">Uploading</span>
                      <span
                        className="spinner-grow spinner-grow-1 me-1"
                        role="status"
                      ></span>
                      <span
                        className="spinner-grow spinner-grow-2 me-1"
                        role="status"
                      ></span>
                      <span className="spinner-grow me-1" role="status"></span>
                    </span>
                  ) : null}
                </div>
              )}
              <div className="pb-4"></div>
            </form>
          </div>

          {!datesTouched &&
          dates.refreshTimeHorizonTable &&
          timeHorizonValues.length > 0 ? (
            <React.Fragment>{getTimeHorizonTemplate()}</React.Fragment>
          ) : (
            ""
          )}

          {showTimeHorizonValuesError ? (
            <div className="col-12 mb-3 text-danger">
              Please check valid from and valid to dates. Time horizon should be
              rolling year.
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row text-danger m-3">
          Please add project categories before adding projects.
          <div className="col">
            <NavLink to={`/projects/categories`} className="button extra-small">
              Click to add
            </NavLink>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateProject;
